import pdfMake from 'pdfmake';
import handlePurchase from "@/services/modules/purchase";
import pdfFonts from 'pdfmake/build/vfs_fonts';
import figureFormatter from "@/services/utils/figureFormatter";
import handleHospital from "@/services/modules/hospital";
import pdfHeaderFooter from '@/services/utils/pdf/pdfHeaderFooter';
import useDate from '@/services/utils/day';
import {getProductParticulars} from "@/services/utils/global";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}

const pdfPrinter = () => {
    const { currencyToWord } = handlePurchase();
    const { onlyCommaFormat } = figureFormatter();
    const { calculateAge, capitalize } = handleHospital();
    const { getHeader, getFooter } = pdfHeaderFooter();
    const { formatDateWithTime } = useDate();

    const exportToPDF = (company, data, barcode, qrcode, barcodeCustomer, userName, billType) => {
        const tableItems = data.has_item_detail ? data.general : data.ledgers;

        var doc = { 
            pageSize: 'A4',
            pageMargins: [ 60, 100, 60, 80 ],
            header: getHeader(company),
            footer: getFooter(userName, qrcode),
            content: getContent(data, tableItems, barcode, barcodeCustomer, userName, billType),

            styles : {
                header: {
                    fontSize: 24,
                }
            },
            defaultStyle: {
                color: 'black',
                fontSize: 10,
                font: 'SulaimanLipi'
            },
            info: {
                title: data.bill_number
            }
        }

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const getContent = (data, tableItems, barcode, barcodeCustomer, userName, billType) => {
        return  [
            {
                text: 'Return Bill',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
            },

            {
                margin: [0, 20, 0, 0],
                alignment: 'justify',
                columns: [
                    {
                        width: '50%',
                        stack: [
                            {
                                svg: barcodeCustomer
                            },

                            {
                                text: `Customer ID: ${data.contact.serial}`,
                                alignment: 'left',
                                width: '50%'
                            },

                            {
                                text: `Customer Name: ${data.contact.name}`,
                                alignment: 'left',
                                width: '50%'
                            },

                            {
                                text: `${data.contact.birthday ? ('Age: ' + calculateAge(data.contact.birthday) + '; ' + capitalize(data.contact.gender)) : ''}`,
                                alignment: 'left',
                                width: '50%'
                            },

                            {
                                text: `Customer Phone No: ${data.contact.mobile_no}`,
                                alignment: 'left',
                                width: '50%'
                            },

                            {
                                text: `${data.contact.address ? 'Customer Address: ' + data.contact.address : ''}`,
                                alignment: 'left',
                                width: '50%'
                            },
                        ].filter(contact => contact !== null)

                    },

                    {
                        alignment: 'right',
                        stack: [
                            {
                                svg: barcode
                            },

                            {
                                text: [
                                    {
                                        text: 'Credit Note No: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },

                                    {
                                        text: data.bill_number
                                    }
                                 ]
                            },

                            {
                                text: [
                                    {
                                        text: 'Credit Note Date: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },

                                    {
                                        text: data.date,
                                    }
                                 ]
                            } 
                        ]
                    },
                ]
            },

            {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    widths: ['*', 'auto', 'auto', 'auto'],
                    body: getTableBody(tableItems, data.has_item_detail, billType)
                },
                layout: {
			        fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0.5;
                    }
		        }
		    },

            {
                columns: [
                    {
                        width: '65%',
                        stack: [
                            {
                                text: data.total_amount ? ('In Words: ' + currencyToWord(data.total_amount)) : '' 
                            },

                            {
                                margin: [15, 25],
                                fontSize: 14,
                                table: {
                                    body: [
                                        [{ text: 'Returned', margin: [10, 5] }]
                                    ],
                                },
                                layout: {
                                    fillColor: '#f3f2f7'
                                }
                            },
                        ]
                    },
                     
                    {
                        table: {
                            widths: ['*', '*'],
                            alignment: 'right',
                            body: [
                                [{ text: 'Total Amount', alignment: 'right', bold: true }, { text: getSubTotalAmount(data, billType), alignment: 'right'}],
                                [ { text: 'VAT Amount', alignment: 'right', bold: true }, {text:  data.vat_amount, alignment: 'right' } ],
                                [ { text: 'Discount', alignment: 'right', bold: true }, {text:  getDiscount(data, billType), alignment: 'right' } ],
                                [ { text: 'Net Return Amount', alignment: 'right', bold: true }, { text: onlyCommaFormat(data.total_amount), alignment: 'right'}]
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return 0.5;
                            }
                        }
                    },
                ],
                margin: [0, 10]
            },

            {
                alignment: 'justify',
                text: [{ text: data.description ? 'NOTE: \n' : '', bold: true}, {text: data.description} ]
            },

            data.receipt_masters.length > 0 ? 

            {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto'],
                    body: getReceiptTable(data.receipt_masters)
                },
                layout: {
			        fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0.5;
                    }
		        }
		    } : ''
        ]
    }

    const getDiscount = (data, billType) => {
        if(billType === 'opd_invoice') {
            return onlyCommaFormat(data.ledgers[0].amount - data.paid_amount)
        }
        return onlyCommaFormat(data.total_discount)
    }

    const getSubTotalAmount = (data, billType) => {
        if(billType === 'opd_invoice') {
            return onlyCommaFormat(data.ledgers[0].amount) 
        }
        return onlyCommaFormat(data.subtotal_amount)
    }

    const getReceiptTable = (receiptMasters) => {
        return [
            [
                { text: 'Date & Time'},
                { text: 'Return No' },
                { text: 'Mode of payment' },
                { text: 'MoP Ref.' },
                { text: 'Refund by' },
                { text: 'Refund Amount', alignment: 'right' }
            ],
            [
                { text: formatDateWithTime(receiptMasters[0].created_at) },
                { text: receiptMasters[0].voucher_no },
                { text: receiptMasters[0].receipt_reference },
                { text: receiptMasters[0].account_head.name },
                { text: receiptMasters[0].created_user.name },
                { text: onlyCommaFormat(receiptMasters[0].refund_amount), alignment: 'right' }
            ]
        ]
    }

    const getTableBody = (tableItems, hasItemDetail, billType) => {
        const header = getTableHead();
        const row = getTableRow(tableItems, hasItemDetail, billType);
        return [
            header,
            ...row
        ]
    }

    const getTableHead = () => {
        return [
            { text: 'Particulars' },
            { text: 'Quantity' , alignment: 'right' },
            { text: 'Unit Price' , alignment: 'right' },
            { text: 'Amount' , alignment: 'right' }
        ]
    }

    const getTableRow = (tableItems, hasItemDetail, billType) => {
        const tableData = [];
        const isOpdBill = billType === 'opd_invoice';

        tableItems.map((el, index) => {
            if (isOpdBill && index > 0) {
                return;
            }

            const row = [
                { text: getProductParticulars(hasItemDetail, el) },
                { text: hasItemDetail ? el.quantity : '', alignment: 'right' },
                { text: hasItemDetail ? onlyCommaFormat(el.rate) : '', alignment: 'right' },
                { text: onlyCommaFormat(! isOpdBill ? (el.rate * el.quantity) : el.amount), alignment: 'right' }
            ];
            tableData.push(row);
        })
        return tableData
    }

    return {
        exportToPDF
    }
}

export default pdfPrinter;
